<template>
    <div class="Detail">
        <!-- <span v-if="is_fenxiang" class="fenxiangcc" @click.prevent="clicknavigateTo('/pages/activity/coupons/index?id=' + id +'&spid='+ spid)">
            <div class="iconfont iconfenxiang1"></div>
            <div>招商</div>
        </span> -->
        <div class="infotab" ref="topInfo">
                <Tabs v-model="activeName2" title-active-color="#000" class="topFixed" @click="htmlLocation()">
                    <tab  title="参数" name="c" ></tab>
                    <tab  title="相关视频" name="b"></tab>
                    <!-- <tab  title="包装广告" name="f"></tab> -->
                    <tab  title="产品详情" name="a"></tab>
                    <!-- <tab  title="杯酒论人生" name="d" ></tab> -->
                    
                    <!-- <tab  title="店面" name="e"></tab> -->
                </Tabs>
            </div>
        <!-- <BrandList v-on:clickBrand="clickBrand" :activeName="activeName"></BrandList> -->
        <div class="brand">
            <TitleList v-on:clickBrand="clickBrand" v-on:brandListdata="brandListdata" :activeName="activeName" ></titleList>
        </div>
        <div class="branditem" ref="cInfo">
            <div class="branditem_hd">
                <div class="picbg">
                    <VanImage width="100%" height="auto" lazy-load :src="require('@assets/images/brand/brandtitbg.png')" />
                </div>
                <div class="pic" v-if="info.brand">
                    <VanImage width="100%" height="auto" lazy-load :src="info.brand.logo" />
                </div>
                <div class="goodslist">
                    <div class="dpic" v-for="(item, index) in goodsList" :key="index" @click="product(item.id)" :class="item.id==id?'curdpic':''">
                        <VanImage width="100%" height="100%" lazy-load fit="cover" :src="item.wcj_images" />
                    </div>
                </div>
            </div>
            <div class="branditem_bd">
                <Swipe :initial-swipe="current" :duration="500" :autoplay="5000" :loop="false" :show-indicators="true" indicator-color="#e93323">
                    <SwipeItem v-for="(item, index) in SwipeList" :key="index">
                        <!-- <div class="Swipecon" v-if="index==0">
                            <div class="tit">
                                <p>{{info.en_name}}</p>
                                <p>{{info.store_name}}</p>
                            </div>
                            <div class="con" v-if="info">
                                <div v-if="info.cate_id==33">
                                    <p>
                                        <span class="greybg">类型</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.winelever.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">产地</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.countries.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">酒精度</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.vol.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">净含量</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.netweight.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">质量等级</span>
                                        <a  class="greybg">：</a>
                                        <span>优级</span>
                                    </p>
                                    <p>
                                        <span class="greybg">生产日期</span>
                                        <a  class="greybg">：</a>
                                        <span>见瓶身</span>
                                    </p>
                                    <p>
                                        <span class="greybg">原料</span>
                                        <a  class="greybg">：</a>
                                        <span v-for="(item, index) in info.grape">
                                            {{item.brand_name}}、
                                        </span>
                                    </p>
                                    <p>
                                        <span class="greybg">贮存条件</span>
                                        <a  class="greybg">：</a>
                                        <span>干燥，常温下保存</span>
                                    </p>
                                     <p>
                                        <span class="greybg">执行标准</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.standardcode}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">生产许可证</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.qcode}}</span>
                                    </p>                                   
                                    <p>
                                        <span class="greybg">条形码</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.bar_code}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">温馨提示</span>
                                        <a  class="greybg">：</a>
                                        <span>过量饮酒，有害健康</span>
                                    </p>
                                </div>
                                <div v-else>
                                    <p>
                                        <span>国&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;家：</span>
                                        <span>{{info.countries.country}} {{info.chateau.english_name}}</span>
                                    </p>
                                    <p>
                                        <span>酒&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;庄：</span>
                                        <span>{{info.chateau.brand_name}}（{{info.chateau.history}}）</span>
                                    </p>
                                    <p>
                                        <span>品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;牌：</span>
                                        <span>{{info.brand.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span>产&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;区：</span>
                                        <span>{{info.countries.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span>年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;份：</span>
                                        <span>{{info.year.brand_name}}年</span>
                                    </p>
                                    <p>
                                        <span>级&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：</span>
                                        <span>{{info.winelever.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span>品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;种：</span>
                                        <span v-for="(item, index) in info.grape">
                                            {{item.brand_name}}、
                                        </span>
                                    </p>
                                    <p>
                                        <span>酒&nbsp;精&nbsp;度：</span>
                                        <span>{{info.vol.brand_name}}%</span>
                                    </p>
                                    <p>
                                        <span>熟&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;化：</span>
                                        <span>{{info.aging.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span>容&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;量：</span>
                                        <span>{{info.netweight.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span>食物搭配：</span>
                                        <span v-for="(item, index) in info.collocation">
                                            {{item.brand_name}}、
                                        </span>
                                    </p>
                                    <div class="aroma">
                                        <div class="aromapic">
                                                <VanImage width="100%" height="100%" lazy-load :src="require('@assets/images/goods/aroma.jpg')" />
                                            </div>
                                            <div class="aromap">
                                            {{info.aroma}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cons" v-if="info.cate_id==33">
                                <div class="conBom">
                                    <p>
                                        <span class="greybg">委托方</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.entrust}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">生产方</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.produce}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">地址</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.countries.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">联系电话</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.phone}}</span>
                                    </p>
                                </div>
                            </div>
                            <div v-if="info.prize">
                                <div class="Awarded" v-if="info.prize.length">
                                    <div class="Awardedpic">
                                        <VanImage width="100%" height="100%" lazy-load fit="cover" :src="require('@assets/images/goods/Awardedtit.png')" />
                                    </div>
                                    <div class="Awardedlist">
                                        <div class="Awardeditem" v-for="(item, index) in info.prize">
                                            <VanImage width="100%" height="100%" lazy-load fit="cover" :src="item.image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="dpic">
                                <VanImage width="100%" height="100%" lazy-load fit="cover" :src="info.wcj_images" />
                            </div>
                        </div> -->
                        <div class="Swipepic" >
                            <VanImage width="100%" height="100%" lazy-load fit="cover" :src="item.url" />
                        </div>
                    </SwipeItem>
                </Swipe>
            </div>
        </div>
        <div class="canshu">
            <div class="item" v-for="(item, index) in mao" :key="index">
                <div v-if="item.name">
                    <div class="tit" >{{item.name}}</div>
                    <div  v-for="(sonitem, sonindex) in item.list" :key="sonindex"><span>{{sonitem}}</span></div>
                </div>
                <div  v-else>
                    <div  v-for="(sonitem, sonindex) in item.list" :key="sonindex"><span class="tits">{{sonitem}}</span></div>
                </div>
                
            </div>
        </div>
        <GoodsList :goodsList="info.goods"></GoodsList>
        
        <a class="openMap" href="/Tasting/goods/Salelist" v-if="store_info.is_type==0&&info.id==36907">
            <span  class="map-font">查看附近销售点</span>
            <span class="map-img">
                <img  height="100px" src="@assets/images/map.png" />
            </span>
         </a>
         <div ref="bInfo">
             <VideoList :VideoList="VideoList" :poster="VideoListimg" :videourl="VideoListurl"></VideoList>
         </div>
         <div ref="fInfo" v-if="adcount>0">
            <div class="adtit acea-row row-between-wrapper">
                <div>产品&广告图
                    <span>Product & Advertising map</span>
                </div>
                <div v-if="adcount>4" class="font-color adPages" @click="goPages('/Tasting/ad/index?id='+id)" >>>更多</div>
            </div>
            <div class="ad-img-box acea-row row-between-wrapper">
                <div class="img-box" v-for="(item,index) in adList" :key="index">
                    <VanImage width="100%" height="100%" :src="item.image" ></VanImage>
                </div>
            </div>
            <!-- id：产品id -->
            <div v-if="adcount>4" class="adPages-bottom font-color" @click="goPages('/Tasting/ad/index?id='+id)">
                <span >查看更多...</span>
            </div>
            <div v-else class="adPages-bottom font-color">
                <span >暂无更多...</span>
            </div>
         </div>
         <div ref="eInfo">
            <PackList :PackList="PackList"  :packListArray="packListArray" ></PackList>
         </div>
        
        <!--杯酒论人生-->
        <!-- <div ref="dInfo" class="comment" style="margin-bottom:0.2rem;margin-top:0rem;">
            <div class="tit">
                <h1>杯酒论人生</h1>
                <div class="judge-img"  @click="goPages('/Tasting/wine/Release?id=' + id)">
                    <img  width="100%" src="@assets/images/judgeBit.png" />
                </div>
                <div class="commentnav">
                    <a href="javascript:void(0);" v-for="(sonitem, index) in info2.scene_list" :key="sonindex">{{sonitem.brand_name}}{{sonitem.num}}</a>
                </div>
            </div>
            <div class="con">
                <div class="comment_item" v-for="(item, index) in list" :key="item" @click="goPages('/Tasting/wine/commentinfo?id=' + item.id)">
                <div class="hd">
                    <div class="userpic">
                        <VanImage width="100%" height="100%" fit="cover" lazy-load :src="item.avatar" />
                    </div>
                    <div class="rgcon">
                        <div class="rghd acea-row row-between-wrapper">
                            <div>
                                <div class="name Ellipses1">{{item.nickname}}</div>
                                <div class="grade">
                                    <span class="bg-color">{{item.rank_name}}</span>
                                    <div class="zhi">
                                        <div>
                                            <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        </div>
                                    </div>
                                    <span class="font-color">酒量值：{{item.exppr}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bd">
                    <div class="rgcon">
                        <div class="goodsname">
                            {{info2.store_name}}
                        </div>
                        <div class="pingfen">
                            <Rate v-model="item.score/2" readonly allow-half :size="25" color="#b34550" void-icon="star" void-color="#eee" />
                        </div>
                        <div class="chosen Ellipses3">
                            <span class="font-color border-color" v-if="item.selected==1">精选</span>
                            {{item.content}}
                        </div>
                        <div class="bpic">
                            <div class="bpic_dd" :class="'bpic_dd' + item.images.length" v-for="(sonitem, sonindex) in item.simages" :key="sonitem" @click.stop="bindImagePreview(index, sonindex)">
                                <VanImage width="100%" height="1.6rem" lazy-load fit="cover" :src="sonitem" />
                            </div>
                        </div>
                        <div class="rgfd acea-row row-between-wrapper">
                            <div>
                                <span style="display: inline-block;float: left;line-height: 0.4rem;margin-right: 0.1rem;">{{item.time}} </span>
                                <span class="Ellipses1" style="display: inline-block;line-height: 0.4rem;float: left;width: 1.6rem;">{{item.city}}</span>
                            </div>
                            <div class="rgfdicon">
                                <Icon name="like-o">{{item.collect_num}}</Icon>
                                <Icon name="chat-o">{{item.comment_num}}</Icon>
                                <Icon name="contact">{{item.comment_ren_num}}</Icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="more font-color" >
                <span class="ed font-color" style="margin-left:0.6rem"  @click="goPages('/Tasting/wine/info?id=' + id)" v-if="info2.scene_list">查看全部{{info2.scene_list[0].num}}条分享 >></span>
            </div>
        </div>
        </div> -->
        <div class="info" ref="aInfo" >
            <div class="infocon">
                <div class="productInfo" >
                    <div class="tit">
                        <h1>详情介绍
                            <span>PRODUCT INTRODUCTION</span>
                        </h1>
                    </div>
                    <Parameter  :description="info.description" v-if="activeShow" ></Parameter>
                </div>
            </div>
        </div>
        <!-- <div ref="dInfo" class="comment" style="margin-bottom:0.2rem;margin-top:0rem;">
            <div class="tit">
                <h1>杯酒论人生</h1>
                <div class="judge-img"  @click="goPages('/Tasting/wine/Release?id=' + id)">
                    <img  width="100%" src="@assets/images/judgeBit.png" />
                </div>
                <div class="commentnav">
                    <a href="javascript:void(0);" v-for="(sonitem, index) in info2.scene_list" :key="sonindex">{{sonitem.brand_name}}{{sonitem.num}}</a>
                </div>
            </div>
            <div class="con">
                <div class="comment_item" v-for="(item, index) in list" :key="item" @click="goPages('/Tasting/wine/commentinfo?id=' + item.id)">
                
                <div class="bd">
                    <div class="rgcon">
                        <div class="chosen Ellipses3">
                            <span class="font-color border-color" v-if="item.selected==1">精选</span>
                            {{item.content}}
                        </div>
                        <div class="bpic">
                            <div class="bpic_dd" :class="'bpic_dd' + item.images.length" v-for="(sonitem, sonindex) in item.simages" :key="sonitem" @click.stop="bindImagePreview(index, sonindex)">
                                <VanImage width="100%" height="1.6rem" lazy-load fit="cover" :src="sonitem" />
                            </div>
                        </div>
                        <div class="hd">
                        <div class="userpic">
                            <VanImage width="100%" height="100%" fit="cover" lazy-load :src="item.avatar" />
                        </div>
                        <div class="rgcon">
                            <div class="rghd acea-row row-between-wrapper fiex-row">
                                <div class="lf">
                                    <div>
                                        <div class="name Ellipses1">{{item.nickname}}</div>
                                        <div class="goodsname Ellipses1">
                                            {{info2.store_name}}
                                        </div>
                                        <div class="time-city Ellipses1">
                                            <span style="display: inline-block;float: left;line-height: 0.4rem;margin-right: 0.1rem;">{{item.time}} </span>
                                            <span class="Ellipses1" style="display: inline-block;line-height: 0.4rem;float: left;">{{item.city}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="ri">
                                    <div class="grade" style="    top: 0;right: 0.2rem;font-size: 0.2rem;">
                                            <span class="bg-color" style="padding: 0 0.1rem;font-size: 0.15rem;">{{item.rank_name}}</span>
                                            <div class="zhi">
                                                <div>
                                                    <VanImage style="margin-top: 0.038rem;" width="0.3rem" height="0.3rem" lazy-load :src="require('@assets/images/grade.png')" />
                                                </div>
                                            </div>
                                            <span class="font-color" style="padding: 0 0.1rem;">酒量值：{{item.exppr}}</span>
                                    </div>
                                    <div class="pingfen">
                                           <Rate v-model="item.score/2" readonly allow-half :size="25" color="#b34550" void-icon="star" void-color="#eee" />
                                    </div>
                                    <div class="rgfdicon">
                                        <Icon name="like-o">{{item.collect_num}}</Icon>
                                        <Icon name="chat-o">{{item.comment_num}}</Icon>
                                        <Icon name="contact">{{item.comment_ren_num}}</Icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rgfd acea-row row-between-wrapper">
                        
                    </div>
                    </div>
                </div>
            </div>
            <div class="more font-color" >
                <span class="ed font-color" style="margin-left:0.6rem"  @click="goPages('/Tasting/wine/info?id=' + id)" v-if="info2.scene_list">查看全部{{info2.scene_list[0].num}}条分享 >></span>
            </div>
        </div>
        </div> -->

        <div v-if="info.chateau">
            <div class="Winery" v-if="info.chateau.image_bg">
                <VanImage width="100%" height="100%" lazy-load fit="cover" :src="info.chateau.image_bg" @click="goPages('/Tasting/goods/Winery?id=' + id)" />
            </div>
        </div>
        
        <!-- <Store :storeinfo="store_info" :storeid="store_id"></Store> -->
            <div style="margin:50px 0"></div>
        <!-- <Backtop></Backtop> -->
        <!-- <Judge></Judge> -->
        <!-- <Selectedpop :show="show" :popdata="popup.data" v-on:setShow="setShow"  ></Selectedpop> -->
        <Activity :showActivity="show2" :popdata="popup.data" v-on:setShowp="setShowp"></Activity>
        <!-- <Posterpop :show="show3" :popdata="popup.data" v-on:setShowPoster="setShowPoster"></Posterpop> -->
    </div>
</template>
<script>
    import BrandList from "../components/brandList";
    import TitleList from "../components/titleList";
    import Backtop from "../components/Backtop";
    import GoodsList from "./components/goodsList";
    import VideoList from "./components/VideoList";
    import PackList from "./components/PackList";
    import NewsList from "./components/NewsList";
    import Parameter from "./components/parameter";
    import Winery from "./components/winery";
    import Picture from "./components/picture";
    import Store from "./components/Store";
    import Judge from "../components/Judge";
    import Selectedpop from "./../components/Selectedpop";
    import Activity from "./../components/Activity";
    import Posterpop from "./../components/Posterpop";
    import { isWeixin_temp } from "@utils/index";
    import {getLocation} from "@libs/wechat";
    import cookie from "@utils/store/cookie";
    import {getPoster, ProduceComplex, ProductVideo,ProductPack,ProductAd, getBrand, getmenu, businessData, JudgeList, JudgeProductdetails ,getSelectedAndActivity,getchk,postaddress } from "@api/tasting";
    import { Icon, Toast, Tab, Tabs, Swipe, SwipeItem, ImagePreview, Rate,Popup } from "vant";
    import { Image as VanImage } from 'vant';
    export default {
        name: "Detail",
        data () {
            return {
                id: '',
                token:'',
                activeName: '',
                brandList: [],
                goodsList: [], //单瓶列表
                goodsList2: [
                    'http://www.tuoers.cn/uploads/attach/2020/10/20201022/9431eccd0a45c4e42ad1609b90194758.png',
                    'http://store.oupuhui.com/uploads/attach/2020/09/20200908/aeb1d6e66bba25ba05d87863de188a0b.jpg',
                ], //商品列表
                current: 0,
                SwipeList: [],
                Slide: [],
                swiperOption: {
                    loop: true, //是否循环
                },
                info: '',
                is_fenxiang:0,
                spid:0,
                VideoList: [],
                VideoListurl: '',
                VideoListimg: '',

                PackList:[],
                packListArray:[],

                activeName2: 'c',
                activeShow:false,
                store_info: '',
                info2: '',
                list: [],
                title:'产品介绍『点击展开』',
                topFixed:'offFixed',
                store_id:0,
                //offFixed:false,
                mapurl:'',
                popup:{
                    data:[],
                },
                show:false,
                show2:false,
                show3:false,
                showCode:0,
                Suspension:false,
                apos:'',
                bpos:'',
                cpos:'',
                dpos:'',

                lat : this.$store.state.app.latitude,
                lng : this.$store.state.app.longitude,

                adList:[
                    
                ],
                adcount:0,
            }
        },
        components: {
            Icon, BrandList, VanImage, Backtop, Tab, Tabs, GoodsList, VideoList,PackList, NewsList, Parameter, Winery, Picture, Store, Swipe, SwipeItem, ImagePreview, Rate ,Judge
            ,Selectedpop,Activity,Popup,Posterpop,Toast,TitleList
        },
        created() {
            this.id=this.$route.query.id;
            this.token=this.$route.query.token;
            this.store_id=this.$route.query.store_id;
            this.code = this.$route.query.code;//核销码
            this.chk = this.$route.query.chk;//防伪nfc标签
            this.pcode = this.$route.query.pcode!=undefined?this.$route.query.pcode:0;
        },
        mounted: function() {
            //this.getMap();
            //this.getchk();
            this.getlocations();
            this.getProduceComplex();
            this.setToken();
            this.setTopFixed();
            this.setstoreid();
            //this.getPopupData();
            //this.SetPopup();//弹窗
        },
        methods: {
            clicknavigateTo: function(url) {
                wx.miniProgram.navigateTo({url: url})
            },
            getAd:function(){
                ProductAd({
                    page:1,
                    limit:4,
                    id:this.id
                }).then(res=>{
                    this.adList = res.data.data;
                    this.adcount = res.data.count;
                })
            },
            getchk:function(){
                if(this.chk=="undefined")return;
                if(!isWeixin_temp()){
                    getchk({
                        chk:this.chk,
                        pcode:this.pcode,
                    }).then(res=>{
                        let _data = res.data;
                        if(_data.verifyResult){
                            alert('防伪验证成功！已被验证了'+_data.queryCount+'次'+'!');
                        }else{
                            alert('验证失败');
                        }
                        console.log(res);
                    })
                }
            },
            setShow:function(){
                this.show = !this.show;
            },
            setShowp:function(){
                this.show2 = !this.show2;
            },
            setShowPoster:function(){
                this.show3 = !this.show3;
            },
            //弹窗类型
            SetPopup:function(code){
                //this.showCode = 1;
                if(code==1){
                    if(this.show3)return;
                    this.showCode = 1;
                    this.show = true;
                    this.show2 = false;
                }else if(code==2){
                    this.showCode = 1;
                    this.show2 = true;
                    this.show = false;
                }else{
                    if(this.show3)return;
                    //this.showCode = 1;
                    this.show = false;
                    this.show2 = false;
                }
            },
            getPosters:function(code){
                getPoster({
                    product_id:this.$route.query.id
                }).then(res=>{
                    if(res.data!=''){
                        this.popup.data = res.data;
                        this.show3 = true;
                        this.showCode = 1;
                    }
                    this.SetPopup(code);
                })
            },
            //获得popup数据
            getPopupData(brand_id){
                if(this.token==undefined ||!this.token)return;
                if(this.showCode)return;
                getSelectedAndActivity({
                    code:this.code,
                    brand_id:brand_id
                }).then(res=>{
                    this.popup.data = res.data.data;
                    this.getPosters(res.data.code);
                    
                })
            },
            setTopFixed:function(){
                var menu = this.$refs.topInfo;
                var aa = this.$refs.aInfo;//详情
                var bb = this.$refs.bInfo;//视频
                var cc = this.$refs.cInfo;//参数
                //var dd = this.$refs.dInfo;//杯酒
                var _this  = this;
                window.addEventListener("scroll", () => {
                    var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                    menu.style.opacity = scrollTop/200;
                    if(scrollTop==0){
                        menu.style.display = 'none';
                    }else if(scrollTop>50){
                        menu.style.display = 'block';
                    }
                    if(menu.style.display == 'block'){
                        _this.activeShow=true;
                    }
                    //if(this.Suspension){return;}
                     _this.cpos = cc.getBoundingClientRect().top;
                    if (_this.cpos <= 0 && _this.cpos>=(-cc.offsetHeight)) {
                        _this.activeName2 = 'c';
                    }
                     _this.bpos = bb.getBoundingClientRect().top;
                    if (_this.bpos-84 <= 0 && _this.bpos>=(-bb.offsetHeight)) {
                        _this.activeName2 = 'b';
                    }
                    //  _this.dpos= dd.getBoundingClientRect().top;
                    // if (_this.dpos-84 <= 0 && _this.dpos>=(-dd.offsetHeight)) {
                    //     _this.activeName2 = 'd';
                    // }
                     _this.apos = aa.getBoundingClientRect().top;
                    if ( _this.apos-84 <= 0 &&  _this.apos>=(-aa.offsetHeight)) {
                        _this.activeName2 = 'a';
                    }
                    //console.log('a:'+_this.apos);
                    //console.log('b:'+_this.bpos);
                    //console.log('c:'+_this.cpos);
                    //console.log('d:'+_this.dpos);
                    console.log('f:'+_this.fpos);
                });
            },
            htmlLocation: function(){
                var _this  = this;
                var pos = '';
                switch(_this.activeName2){
                    case "a":
                        pos = _this.apos;
                        break
                    case "b":
                        pos = _this.bpos;
                        break
                    case "c":
                        pos = _this.cpos;
                        break
                    // case "d":
                    //     pos = _this.dpos;
                    //     break    
                    default:
                        pos = _this.cpos;
                        break
                }
                this.Suspension = true;
                if(_this.activeName2=='c'){
                    var timer = setInterval(function(){
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                    let ispeed = Math.floor(-osTop / 5);
                    document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                    if(osTop === 0){
                        clearInterval(timer);
                    }
                    },30)
                    return
                }else{
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                    document.documentElement.scrollTop = document.body.scrollTop = osTop+pos-94;
                        
                }
                
            },
            // setSuspension:function(){
            //     let that = this;
            //     this.activeName2='a'
            //     var timer = setInterval(function(){
            //         let osTop = that.$refs.topInfo.getBoundingClientRect().top;
            //         let ispeed = Math.floor(-osTop / 5);
            //         console.log(osTop);
            //         console.log(ispeed);
            //         console.log(osTop+ispeed);
            //         document.documentElement.scrollTop = document.body.scrollTop = osTop+ispeed;
            //         this.Suspension = true;
            //         if(osTop){
            //             clearInterval(timer);
            //         }
            //     },30)
            // },
            setActiveShow:function(){
                this.activeShow =  this.activeShow?true:false;
            },
            test:function(){
                this.title = (this.title == '产品介绍『点击展开』')?'产品介绍『点击收起』':'产品介绍『点击展开』'; 
            },
            setToken:function(token){
                console.log('tokens:'+this.$store.state.app.token);
                if(!this.token || this.token==undefined) return true;
                if(!this.$store.state.app.token) this.$store.state.app.token = this.token;
                
            },
            setstoreid:function(){
                if(!this.$store.state.app.storeid) this.$store.state.app.storeid = this.store_id;

                cookie.set('storeid',this.store_id);
                //console.log(this.$store.state.app.storeid+'abc');
            },
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            //预览图片
            bindImagePreview: function(index, sonidnex) {
                ImagePreview({
                    images: this.list[index].images,
                    startPosition: sonidnex,
                });
            },
            clickBrand: function (id) {
                this.activeShow = false;
                //this.title = '产品介绍『点击展开』';
                //this.activeName2='d';//重置侧换,加快初始加载速度
                this.$router.push({ path: '/Tasting/goods/detail?id=' + id });
            },
            product: function(id) {
                this.activeShow = false;
                //this.title = '产品介绍『点击展开』';
                //this.activeName2='d';//重置侧换,加快初始加载速度
                this.id=id;
                this.$router.push({ path: '/Tasting/goods/detail?id=' + this.id });
            },
            getProduceComplex: function() {
                Toast.loading({
                    duration: 0,
                    message: '加载中...',
                    forbidClick: true,
                });
                ProduceComplex({
                    id: this.id,
                    store_id:this.$store.state.app.storeid||this.store_id
                }).then(res => {
                    Toast.clear();
                    this.activeName=res.data.product.brand.id;
                    this.info=res.data.product;
                    this.is_fenxiang = res.data.is_fenxiang;
                    this.spid = res.data.spid;
                    var mao=res.data.mao;
                    mao.forEach(function(item) {
                        var reg=/\\n/g;
                        item.value2=item.value.replace(reg,'/');
                        item.list=item.value2.split('/');
                    })
                    this.mao=mao;
                    this.getmenu(this.activeName);
                    var that=this;
                    let images=res.data.images;
                    images.forEach(function(item) {
                        that.SwipeList.push(item);
                    })

                    this.getbusinessData();
                    this.ProductVideo();
                    this.ProductPack();
                    this.getJudgeProductdetails();
                    this.getJudgeList();
                    this.getPopupData(this.activeName);
                    this.getAd();
                })
                .catch(err => {
                    //console.log(err)
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getJudgeProductdetails: function() {
                JudgeProductdetails({
                    id: this.id,
                }).then(res => {
                    this.info2=res.data;
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getJudgeList: function() {
                JudgeList({
                    product_id: this.id,
                    page: 1,
                    limit: 8,
                    type: 1,
                    category_id: '',
                }).then(res => {
                    this.list = res.data.list;
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getbusinessData: function() {
                businessData({
                    store_id:this.$store.state.app.storeid||this.store_id
                }).then(res => {
                    this.store_info=res.data;
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            ProductVideo: function(id) {
                ProductVideo({
                    id: this.id
                }).then(res => {
                    this.VideoList=res.data;
                    if(this.VideoList.length>0){
                        this.VideoListurl= this.VideoList[0].video;
                        this.VideoListimg= this.VideoList[0].images;
                    }
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            ProductPack: function(id) {
                ProductPack({
                    id: this.id
                }).then(res => {
                    this.PackList=res.data.data;
                    if(this.PackList.length>0){
                        this.packListArray= this.PackList[0];
                    }
                    console.log(this.packListArray);
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getmenu: function(id) {
                getmenu({
                    id:id,
                    level:3,
                }).then(res => {
                    this.goodsList=res.data;
                })
            },

            //获取定位
            getlocations(){
                if(this.store_id&&this.store_id!=undefined)return true;
                if((this.$store.state.app.latitude==undefined||this.$store.state.app.longitude==undefined)){
                    if(isWeixin_temp()){
                            let config = {
                                    type: 'gcj02'
                            };
                            getLocation().then(res => {
                                this.$store.state.app.latitude = res.latitude;// 纬度，浮点数，范围为90 ~ -90
                                this.$store.state.app.longitude = res.longitude;//经度，浮点数，范围为180 ~ -180。
                                this.lat = res.latitude;
                                this.lng = res.longitude;
                                this.getPostaddress(res.latitude,res.longitude);
                            }).catch(err => {
                                alert(err);
                                Toast.fail('错咯');
                                if (err.is_ready) {
                                    err.wx.getLocation();
                                }
                            });
                    }else{
                        Toast.fail('请到微信访问');
                        this.mapUrl='https://apis.map.qq.com/tools/geolocation?key=QQUBZ-OGSWP-6ESDL-LGQYP-OPCVQ-KVB67&referer=wcj';
                    }
                }else{
                    //Toast.fail('出错啦001');
                     this.getPostaddress(this.$store.state.app.latitude,this.$store.state.app.longitude);
                }
            },
            getPostaddress(latitude,longitude){
                var that = this;
                Toast.loading({
                        duration: 0,
                        forbidClick: true,
                });
                postaddress({
                    latitude: latitude,
                    longitude: longitude
                }).then(res=>{
                    Toast.clear();
                    that.$store.state.app.storeid = res.data.store.id;
                    that.store_id = res.data.store.id;
                    //alert(this.$store.state.app.storeid);
                }).catch(err=>{
                    Toast.clear();
                    Toast.fail(err);
                })
            },

        },
        watch:{
            $route(to,from){
                this.id=to.query.id;
                this.SwipeList=[];
                this.getProduceComplex();
            },
            
        }
    }
</script>

<style scoped lang="scss">
// .infotab{
//     opacity: 0;
//     position: fixed;
//     z-index: 100;
//     width: 100%;
// }
.brand{
    width: 6.5rem;
    background: #f4f4f4;
    position: fixed;
    z-index: 1100;
    top:0.02rem
}
.openMap{
    display: flex;
    justify-content:space-around;
    align-items:center;
    background-color: #fff;
    //padding: 0.2rem;
    margin-bottom: 0.2rem;
    //text-align: right;
    span{
        width: 50%;
         
    }
    .map-img{
        text-align: right;
    }
    .map-font{
        text-align: center;
    }
}
::v-deep{
        .infotab .van-tabs__line{
        transform: translateX(47px) translateX(-50%); 
        }
    }
.infotab{
    position: fixed;
    top: 1.19rem;
    z-index: 1008;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    opacity: 0;
    display: none;
}
::v-deep{
    // .van-rate{
    //     position:absolute;
    //     top:0.5rem;
    //     right: 0.2rem;
    // }
    .van-icon-star{font-size: 0.32rem!important;}
    .van-rate__item{padding-right: 0.05rem;}
}
::v-deep {
    .brandList{
        .van-tab{padding:0.3rem 0.1rem;}
        .van-tabs__wrap {
            height: 1.2rem;
        }
        .van-tabs__line{background:none;}
        .van-tabs__line::before{
            background:#f4f4f4;
            border-left: 1px solid #b6b6b6;
            border-top: 1px solid #b6b6b6;
            width: 0.2rem;
            height: 0.2rem;
            display: block;
            content: '';
            margin: 0 auto;transform: rotate(45deg);
        }
    }
    .van-swipe__indicator{width: 0.18rem;height:0.18rem;background-color:#a2a2a2;}
    .info{
        .van-tabs__line{background:#000;}
    }
}
.hd{
    img{width: 100%;}
}
.brandList{
    border-bottom:1px solid #eee;
    .brandpic{
        border: 1px solid #4c4744;border-radius: 0.1rem;overflow: hidden;height: 0.7rem;width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{width: auto;height: 100%;}
    }
}
.branditem{
    background:#fff;overflow: hidden;padding-top: 0.2rem;
    .branditem_hd{
        height:1.2rem;background:#f4f4f4;margin-bottom: 0.8rem;position:relative;
        .pic{position:absolute;left: 0.2rem;width:1.82rem;height:1.6rem;border:1px solid #e8e8e8;overflow:hidden;border-radius:0.1rem;z-index:2;}
        .goodslist{
            position:absolute;right: 0;overflow-x: scroll;max-width:5.4rem;white-space: nowrap;z-index:2;
            .dpic{margin: 0.2rem 0.2rem 0;width: 0.4rem;display: inline-block;}
            .curdpic{margin: 0;width: 0.9rem;height: 1.7rem;border: 1px solid #cecece;padding: 0 0.2rem 0;display: inline-block;background: #f5f5f5;}
        }
        .picbg{position:absolute;left: 1.84rem;right: 0;z-index:1;}
    }
    .branditem_bd{
        position:relative;
        .van-swipe{height: 10.4rem;}
        .Swipepic{height: 10.4rem;width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;}
        .Swipecon{
            padding: 0.2rem;position:relative;
            .tit{
                background: #000;color:#fff;margin-top: 0.36rem;padding: 0.1rem 0.1rem 0.1rem 2.8rem;font-size: 0.24rem;
            }
            .con{
                margin-top: 0.1rem;padding: 0.1rem 0 0.1rem 2.7rem;font-size: 0.24rem;min-height: 6.6rem;
                p{
                    margin: 0.08rem 0;overflow:hidden;display: flex;
                    span{float:left;}
                    span:first-child{
                        //width: 1.6rem;text-align: right;
                        width: 1.5rem;
                        height: 0.34rem;
                        line-height: 0.34rem;
                        padding:0 0.1rem;
                        //text-align: right;
                        text-align: justify;
                        }
                    span:last-child{flex: 1;margin-left: 0.1rem;}
                    span:first-child::after {
                        display: inline-block;
                        width: 100%;
                        content: "";
                        }
                }
                .aroma{
                    border: 0.1rem solid #eee;line-height: 0.3rem;overflow: hidden;
                    .aromapic{
                        width: 1rem;height:1rem;float:left;
                    }
                    .aromap{padding: 0.1rem 0 0.1rem 1rem;}
                }
            }
            .cons{
                    border-bottom: 1px dashed #ccc;
                    border-top: 1px dashed #ccc;
                    position: relative;
                    z-index: 100;
                    padding: 0.1rem 0;
                    bottom: 0.5rem; 
                .conBom{
                    font-size: 0.24rem;
                    p{
                        margin: 0.06rem 0;overflow:hidden;display: flex;
                        span{float:left;}
                        span:first-child{
                        width: 1.4rem;
                        height: 0.34rem;
                        line-height: 0.34rem;
                        padding-left:0.1rem;
                        //text-align: right;
                        text-align: justify;
                        }
                        span:last-child{flex: 1;margin-left: 0.1rem;}
                        span:first-child::after {
                        display: inline-block;
                        width: 100%;
                        content: "";
                        }
                    }
                    
                }
            }
            .Awarded{
                overflow:hidden;
                .Awardedpic{float:left;width: 2.6rem;padding: 0.2rem 0;}
                .Awardedlist{
                    display: inline-block;overflow: scroll;float:left;width: 4.4rem;white-space:nowrap;
                    .Awardeditem{
                        display: inline-block;width: 0.8rem;height:0.8rem;margin: 0 0.02rem;
                    }
                }
            }
            .dpic{position:absolute;left:0.4rem;top:0;width: 2.4rem;height:8rem;background:#fff;}
        }
    }
}

.canshu{
    overflow-x: auto;background:#fff;padding:0.3rem 0;margin-top:0.2rem;
    .item {
        border-left: 1px solid #eee;padding: 0 0.16rem;white-space: nowrap;
        display: table-cell;
        span{font-size:0.2rem;color:#999;}
        .tits{
        font-size: 0.28rem;
        color: #000;
        font-weight: normal;
        line-height: 0.5rem;
        }
    }
    .item:first-child {border-left: none;}
    
}
.productInfo{
    background:#e8e8e8;
    .tit{
        border-bottom: 1px solid #eee;padding: 0.6rem 0.1rem;position:relative;
        h1{
            font-size: 0.45rem;
            color:#7b221e;
            padding-left: 0.3rem;
            span{
                font-size: 0.3rem;
                font-weight:200 ;
                vertical-align: bottom;
            }
        }
    }
}
.comment{
    margin-top: 0.4rem;background:#fff;
    //border-top-left-radius: 0.2rem;border-top-right-radius: 0.2rem;
    .tit{
        border-bottom: 1px solid #eee;padding: 0.2rem 0;position:relative;
        h1{text-align: center;font-size: 0.36rem;}
        .judge-img{
            margin-top:0.2rem;
        }
        .ed{position:absolute;top:0;right:0.2rem;line-height:0.9rem;}
        .commentnav{
            font-size: 0.24rem;overflow:hidden;margin-top: 0.1rem;
            a{
                display: inline-block;float: left;margin: 0.1rem;border: 1px solid #eee;line-height: 0.5rem;padding: 0 0.1rem;border-radius: 0.1rem;
            }
            
        }
    }
    .comment_item{
        position: relative;
        border-bottom: 1px solid #eee;padding: 0.2rem;font-size: 0.24rem;
        .hd{
            position:relative;
            .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
            .rgcon{
                padding-left: 1.15rem;color: #999;
                height: 1.2rem;
                .fiex-row{
                    display: flex;
                }
                .lf{
                    width: 45%;
                    .time-city{
                        padding-top: 0.1rem;
                    }
                }
                .ri{
                    
                }
                .rghd{
                    height:1rem;
                    .name{max-width: 3rem;font-size: 0.24rem;color:#000;margin-top: 0.1rem;}
                    .grade{
                        margin-top: 0.05rem;
                        span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.05rem;border-radius:0.08rem;}
                        span:first-child{padding: 0 0.2rem;}
                        .zhi{
                            float:left;text-align: right;margin: 0 0.05rem 0 0.2rem;
                            .van-image{margin-left:-0.12rem;}
                        }
                    }
                    .zhi{text-align: right;}
                }
                .goodsname{font-size: 0.24rem;margin-top: 0.1rem;}
                .pingfen{padding: 0.1rem 0 ;}
                .label{
                    overflow:hidden;
                    span{display:block;float:left;margin-right:0.2rem;}
                }
                .chosen{
                    color:#333;line-height:0.4rem;padding: 0.1rem 0;font-size:0.28rem;
                    span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;font-size:0.24rem;}
                }
                .bpic{
                    overflow:hidden;
                    .bpic_dd{
                        width:33.333%;padding:0.05rem;float:left;
                        .van-image{}
                    }
                    .bpic_dd1{width:100%!important;}
                    .bpic_dd4{width:50%!important;}
                }
                .sharegoods{
                    background:#f3f3f5;padding: 0.1rem;overflow:hidden;
                    .sharegoods_pic{float:left;width: 1rem;height:1rem;}
                    .sharegoods_con{
                        padding-left: 1.2rem;padding-top:0.1rem;
                        .dd{line-height:0.3rem;color:#333;}
                    }
                }
                .rgfd{
                    padding-top: 0.1rem;
                    .rgfdicon{
                        .van-icon{float:left;margin-left: 0.15rem;}
                        .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
                    }
                }
            }
            .add{
                position: absolute;top:0.24rem;right:0;display:block;font-size:0.24rem;padding:0.1rem;border-radius:0.1rem;border: 1px solid #999;color:#999;
                i{display: flex;}
                i::before{font-size:0.28rem;margin-right:0.05rem;}
            }
        }
        .bd{
            overflow:hidden;
            .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
            .rgcon{
                //padding-left: 1.2rem;
                color: #999;
                .rghd{
                    height:1rem;
                    .name{max-width: 3rem;font-size: 0.28rem;color:#000;}
                    .grade{
                        margin-top: 0.05rem;
                        span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.3rem;border-radius:0.08rem;}
                    }
                    .zhi{text-align: right;}
                }
                // .goodsname{font-size: 0.28rem;padding-left: 1.2rem;

                // }
                .pingfen{
                    //padding: 0.1rem 0;
                    //margin-top: 0.3rem;float:right;margin-right: 0.2rem;
                    text-align: right;
                    }
                .label{
                    overflow:hidden;
                    span{display:block;float:left;margin-right:0.2rem;}
                }
                .chosen{
                    color: #525252;
                    line-height: 0.4rem;
                    padding-top: 0.1rem;
                    font-size: 0.3rem;
                    margin-bottom:0.1rem;
                    span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;}
                }
                .bpic{
                    margin: 0.1rem 0;
                    overflow:hidden;
                    .bpic_dd{
                        width:100%;padding:0.05rem;float:left;
                        .van-image{}
                    }
                    .bpic_dd1{
                        width:100%!important;
                        .van-image{height:5rem!important;}
                    }
                    .bpic_dd3,.bpic_dd5,.bpic_dd6,.bpic_dd7,.bpic_dd8,.bpic_dd9{
                        width:33.33%!important;
                        .van-image{height:2rem!important;}
                    }
                    .bpic_dd4,.bpic_dd2{
                        width:50%!important;
                        .van-image{height:3rem!important;}
                    }
                }
                .sharegoods{
                    background:#f3f3f5;padding: 0.1rem;overflow:hidden;
                    .sharegoods_pic{float:left;width: 1rem;height:1rem;}
                    .sharegoods_con{
                        padding-left: 1.2rem;padding-top:0.1rem;
                        .dd{line-height:0.3rem;color:#333;}
                    }
                }
                .rgfdicon{
                        margin-right: 0.1rem;
                        .van-icon{float:right;margin-left: 0.15rem;}
                        .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
                    }
                .rgfd{
                    padding-top: 0.2rem;
                    
                }
            }
        }
    }
    .more{text-align: center;line-height:0.8rem;}
}

.Winery{
    margin-top: 0.2rem;min-height: 1rem;
}
.test-suspension{
    position: fixed;
    z-index: 5;
    right: 0;
    top: 50%;
    background: rgba(0, 0, 0, 0.534);
    padding: 0.1rem;
    writing-mode:tb-rl;
    color: #fff;
    border-radius: 0.1rem 0 0 0.1rem;
}

.Detail{
    .fenxiangcc{
        position: fixed;
        width: 0.8rem;
        height: 0.8rem;
        z-index: 100;
        border-radius: 0.8rem;
        display: block;
        bottom: 4.1rem;
        text-align: center;
        right: 0.3rem;
        border:1px solid #f00;
        font-size: 0.22rem;
        background-color: #f00;
        color: #fff;
        font-weight: 600;
        .iconfont{
            font-size: 0.22rem;
            margin-top: 0.1rem;
        }
        }
    .adtit{
        position: relative;
        background-color: #e8e8e8;
        padding: 0.6rem 0.35rem;
        font-weight: 600;
        color: #484444;
        font-size: 0.35rem;
        .adPages{
            position: absolute;
            right: 0.08rem;
        }
    }
    .ad-img-box{
        margin: 0.4rem;
        .img-box{
            width:3.2rem;
            height: 4.8rem;
            margin-bottom:0.26rem;
        }
    }
    .adPages-bottom{
        text-align: center;
        padding-bottom: 0.2rem;
        font-weight: 600;
        span{
           margin-left: 0.2rem;
        }
    }
}
</style>